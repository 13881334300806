
import { Component, Vue } from "vue-property-decorator";
import { Customer } from "@/config/Modules";

@Component({
  components: {},
})
export default class CustomerDomains extends Vue {
  protected redirectRouteNameOnDeleteSuccess = "CustomerDashboard";

  protected descriptionField = Customer.description_field;
}
